import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import {toast} from "react-toastify";
import axios from "axios";
import MySQLUrl from "../../DBConfig";

const NewPassportSummary = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [formData, setFormData] = useState({});

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    // Retrieve form data from local storage for each step
                    const storedFormDataStepOne = localStorage.getItem('formDataStepOne');
                    const storedFormDataStepTwo = localStorage.getItem('formDataStepTwo');
                    const storedFormDataStepThree = localStorage.getItem('formDataStepThree');

                    // Check if any form data is missing
                    if (!storedFormDataStepOne || !storedFormDataStepTwo || !storedFormDataStepThree) {
                        // Redirect to '/passport' if any form data is missing
                        navigate('/passport');
                        return;
                    }

                    // Parse and merge form data from all steps into a single object
                    const mergedFormData = {
                        ...JSON.parse(storedFormDataStepOne),
                        ...JSON.parse(storedFormDataStepTwo),
                        ...JSON.parse(storedFormDataStepThree),
                        employee_uid: String(parsedUser.uid)
                    };

                    setFormData(mergedFormData);

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true); // Set loading to true when form is submitted
        setDisabled(true); // Disable submit button

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            console.log('FormData:', formData);

            const response = await axios.post(`${MySQLUrl}/applicant/new`, formData, config);
            // console.log('Response from the server:', response.data);
            toast.success("Passenger added successfully");



        } catch (error) {
            setLoading(false); // Set loading to false when error occurs
            setDisabled(false);
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }


        // Redirect to the next step
        localStorage.removeItem('formDataStepOne');
        localStorage.removeItem('formDataStepTwo');
        localStorage.removeItem('formDataStepThree');
        navigate('/passport');
    };

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Register', url: '/passport/new' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };


    return (
        <>

            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">

                <div className="h-[8vh] w-full px-4">

                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>

                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ):
                                    (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} ({getRoleLabel(User.user_type)})</strong> | {formattedTime}
                                        </div>)}
                            </div>


                        </div>
                    </div>




                </div>

                <div className="bg-neutral-200 h-[85vh] w-full px-4 block">

                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold text-gray-800 uppercase">Finalize registering applicant</h1>

                    </div>


                    <div className="h-7/12 block">

                        <div className="flex justify-center mt-3">

                            <div className="flex items-center">
                                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                    <span className="text-gray-100">1</span>
                                </div>
                                <span className="text-gray-600 ml-2">Applicant Info</span>
                            </div>


                            <div className="flex items-center ml-8">
                                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                    <span className="text-gray-100">2</span>
                                </div>
                                <span className="text-gray-600 ml-2">Applicant Passport</span>
                            </div>

                            <div className="flex items-center ml-8">
                                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                    <span className="text-gray-100">3</span>
                                </div>
                                <span className="text-gray-600 ml-2 ">Guardian Info</span>
                            </div>

                            <div className="flex items-center ml-8">
                                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                    <span className="text-gray-100">4</span>
                                </div>
                                <span className="text-gray-600 ml-2 font-bold">Applicant Overview</span>
                            </div>
                        </div>



                        <div className="mt-3">

                            <div className="flex items-center justify-center text-gray-800">
                                <div className="w-8/12 bg-white items-center justify-center px-4 py-6 rounded-lg mb-4">
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <div>
                                                <div className="justify-center items-center text-center">
                                                    <h1 className="uppercase font-bold text-lg">
                                                        Applicant Bio
                                                    </h1>
                                                </div>
                                                <hr className="mt-2 mb-2"/>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>First Name: </span>
                                                        <span className="font-bold">{formData.fName} </span>
                                                    </div>

                                                    <div>
                                                        <span>Last Name: </span>
                                                        <span className="font-bold">{formData.lName} </span>
                                                    </div>


                                                </div>

                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Full Name: </span>
                                                        <span className="font-bold">{formData.fullName} </span>
                                                    </div>

                                                </div>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Applied Post: </span>
                                                        <span className="font-bold">{formData.postApplied} </span>
                                                    </div>

                                                </div>

                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Applied Post: </span>
                                                        <span className="font-bold">{formData.countries} </span>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div>
                                            <div>
                                                <div className="justify-center items-center text-center">
                                                    <h1 className="uppercase font-bold text-lg">
                                                        Passport Info
                                                    </h1>
                                                </div>
                                                <hr className="mt-2 mb-2"/>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Passport Number: </span>
                                                        <span className="font-bold">{formData.passportNo} </span>
                                                    </div>

                                                </div>

                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Issued Place: </span>
                                                        <span className="font-bold">{formData.issuedPlace} </span>
                                                    </div>

                                                </div>

                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Issued Date: </span>
                                                        <span className="font-bold">{formData.issuedDate} </span>
                                                    </div>

                                                </div>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Expiry Date: </span>
                                                        <span className="font-bold">{formData.expiryDate} </span>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>



                                        <div>
                                            <div>
                                                <div className="justify-center items-center text-center">
                                                    <h1 className="uppercase font-bold text-lg">
                                                        Guardian Info
                                                    </h1>
                                                </div>
                                                <hr className="mt-2 mb-2"/>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>First Name: </span>
                                                        <span className="font-bold">{formData.fNameGuardian} </span>
                                                    </div>

                                                    <div>
                                                        <span>Last Name: </span>
                                                        <span className="font-bold">{formData.lNameGuardian} </span>
                                                    </div>


                                                </div>

                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Full Name: </span>
                                                        <span className="font-bold">{formData.fullNameGuardian} </span>
                                                    </div>

                                                </div>
                                                <div className="flex justify-between mt-2">
                                                    <div>
                                                        <span>Applied Post: </span>
                                                        <span className="font-bold">{formData.guardianMobile} </span>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>



                                        <div className="flex justify-end items-center text-gray-100">
                                            <button
                                                type="submit"
                                                className="bg-green-500 hover:bg-green-900 px-8 py-2 rounded-lg">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>





                    </div>

                    <div className="bg-neutral-200 h-3/12">


                    </div>

                </div>

                <div>
                    <Footer/>
                </div>





            </div>

        </>
    );
};

export default NewPassportSummary;