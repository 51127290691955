import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import {toast} from "react-toastify";
import MySQLUrl from "../../DBConfig";
import axios from "axios";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import PdfViewer from "../../assets/components/common/PDFViewer";

const DetailsApplicant = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [applicant, setApplicant] = useState([]);
    const [showAddCVButton, setShowAddCVButton] = useState(true);
    const [showViewCVButton, setShowViewCVButton] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    const config = {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    };

                    const applicantResponse = await axios.get(`${MySQLUrl}/applicant/cv/${id}`, config);
                    setApplicant(applicantResponse.data);


                    // Check if CV details exist
                    const response = await fetch(`${MySQLUrl}/cv/applicant/${id}`, config);
                    const data = await response.json();
                    if (data.message && data.message === "CV not found for the specified applicant ID") {
                        setShowAddCVButton(true); // Show the button if Applicant not found
                        setShowViewCVButton(false);
                    } else {
                        setShowAddCVButton(false); // Hide the button if CV details exist
                        setShowViewCVButton(true);
                    }

                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicant', url: '/applicant' },
        { label: `${applicant && applicant.fullName}`, url: '/applicant/details' },
    ];

    const goToNext = () =>{
        navigate(`/applicant/new-cv/${applicant && applicant.id}`);
    };

    const goToCV = () =>{
        navigate(`/applicant/cv/${applicant && applicant.id}`);
    };

    const handleViewPassport = (pdfUrl) => {
        // Construct the URL for the PDF viewer route
        const correctedUrl = pdfUrl.replace(/\\/g, '/');

        // Open the PDF viewer route in a new window
        window.open(correctedUrl, '_blank');
    };


    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const passportManagerToast = () => {
        toast.info('Contact Passport Manager for Passport Details Correction')
    }

    return (
        <>
            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">
                <div className="h-[8vh] w-full px-4">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username}</strong> ({getRoleLabel(User.user_type)}) | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-200 h-[85vh] w-full px-4 block text-gray-800">
                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold  uppercase">{applicant && applicant.applicant && applicant.applicant.fullName}</h1>

                    </div>

                    <div className="px-16 py-6 rounded-lg bg-white mt-3 flex">

                            <div className="w-6/12">
                            <div>
                                <div>

                                    <div className="flex justify-center mt-3">

                                        <div className="flex items-center">
                                            <div
                                                onClick={passportManagerToast}
                                                className=" flex items-center justify-center bg-yellow-600 hover:bg-red-900 px-3 py-1 rounded-2xl cursor-pointer">
                                                <span className="text-gray-100">Edit Passport Details</span>
                                            </div>
                                        </div>

                                        {showViewCVButton &&(
                                        <div
                                            onClick={goToCV}
                                            className="flex items-center ml-8">
                                            <div
                                                className=" flex items-center justify-center bg-orange-600 hover:bg-red-900 px-3 py-1 rounded-2xl cursor-pointer">
                                                <span className="text-gray-100">View CV</span>
                                            </div>
                                        </div>
                                            )}

                                        {showAddCVButton &&(
                                            <div
                                                onClick={goToNext}
                                                className="flex items-center ml-8">
                                                <div
                                                    className=" flex items-center justify-center bg-blue-600 hover:bg-blue-900 px-3 py-1 rounded-2xl cursor-pointer">
                                                    <span className="text-gray-100">Add CV</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex items-center ml-8">
                                            <div
                                                className=" flex items-center justify-center bg-green-600 hover:bg-red-900 px-3 py-1 rounded-2xl cursor-pointer">
                                                <span className="text-gray-100">Add Documents</span>
                                            </div>

                                        </div>


                                    </div>


                                    <div className="justify-center items-center text-center mt-3">
                                        <h1 className="uppercase font-bold text-lg">
                                            Applicant Bio
                                        </h1>
                                    </div>
                                    <hr className="mt-1 mb-2"/>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>First Name: </span>
                                            <span className="font-bold">{applicant && applicant.fName} </span>
                                        </div>

                                        <div>
                                            <span>Last Name: </span>
                                            <span className="font-bold">{applicant && applicant.lName} </span>
                                        </div>


                                    </div>

                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Full Name: </span>
                                            <span className="font-bold">{applicant && applicant.fullName} </span>
                                        </div>

                                    </div>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Applied Post: </span>
                                            <span className="font-bold">{applicant && applicant.postApplied} </span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div>
                                <div>
                                    <div className="justify-center items-center text-center">
                                        <h1 className="uppercase font-bold text-lg">
                                            Passport Info
                                        </h1>
                                    </div>
                                    <hr className="mt-2 mb-2"/>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Passport Number: </span>
                                            <span className="font-bold">{applicant && applicant.passport && applicant.passport.passportNo} </span>
                                        </div>

                                    </div>

                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Issued Place: </span>
                                            <span className="font-bold">{applicant && applicant.passport && applicant.passport.issuedPlace} </span>
                                        </div>

                                    </div>

                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Issued Date: </span>
                                            {applicant && applicant.passport && applicant.passport.issuedDate ?
                                                <span className="font-bold">
                                                    {new Date(applicant.passport.issuedDate).toISOString().split('T')[0]}
                                                </span> :
                                                <span className="font-bold">-</span>
                                            }

                                        </div>

                                    </div>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Expiry Date: </span>
                                            {applicant && applicant.passport && applicant.passport.expiryDate ?
                                                <span className="font-bold">
                                                    {new Date(applicant.passport.expiryDate).toISOString().split('T')[0]}
                                                </span> :
                                                <span className="font-bold">-</span>
                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div>
                                <div>
                                    <div className="justify-center items-center text-center">
                                        <h1 className="uppercase font-bold text-lg">
                                            Guardian Info
                                        </h1>
                                    </div>
                                    <hr className="mt-2 mb-2"/>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>First Name: </span>
                                            <span className="font-bold">{applicant && applicant.guardian && applicant.guardian.fNameGuardian} </span>
                                        </div>

                                        <div>
                                            <span>Last Name: </span>
                                            <span className="font-bold">{applicant && applicant.guardian && applicant.guardian.lNameGuardian} </span>
                                        </div>


                                    </div>

                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Full Name: </span>
                                            <span className="font-bold">{applicant && applicant.guardian && applicant.guardian.fullNameGuardian} </span>
                                        </div>

                                    </div>
                                    <div className="flex justify-between mt-1">
                                        <div>
                                            <span>Applied Post: </span>
                                            <span className="font-bold">{applicant && applicant.guardian && applicant.guardian.guardianMobile} </span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="w-6/12 px-4 justify-center items-center">

                            <div>
                                <div className="flex items-center justify-center">
                                    {applicant && applicant.cv && applicant.cv.Photo ? (
                                        <img
                                            className="w-5/12 h-5/12"
                                            src={`${MySQLUrl}/${applicant.cv.Photo}`}
                                            alt="Applicant Photo"
                                        />
                                    ) : (
                                        <span className="text-gray-400">Image not uploaded</span>
                                    )}
                                </div>


                            </div>

                            <div className="">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Document</strong></TableCell>
                                            <TableCell><strong>Availability</strong></TableCell>
                                            <TableCell><strong>View</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow className="py-0">
                                            <TableCell className="py-0">Passport</TableCell>
                                            <TableCell className="py-0">
                                                <span className={applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Passport") ? "text-green-700 font-bold" : "text-red-700 font-bold"}>
                                                    {applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Passport") ? "Available" : "Not Available"}
                                                </span>
                                            </TableCell>
                                            <TableCell className="py-1">
                                                {applicant && applicant.documents && applicant.documents.find(doc => doc.document_type === "Passport") ? (
                                                    <button
                                                        onClick={() => handleViewPassport(`${MySQLUrl}/${applicant.documents.find(doc => doc.document_type === "Passport").path}`)}
                                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                                    >
                                                        View Passport
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="bg-gray-400 text-gray-600 font-bold py-2 px-4 rounded cursor-not-allowed"
                                                        disabled
                                                    >
                                                        Passport Unavailable
                                                    </button>
                                                )}
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="py-0">Medical Report</TableCell>
                                            <TableCell className="py-0">
                                                <span className={applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Medical Report") ? "text-green-700 font-bold" : "text-red-700 font-bold"}>
                                                    {applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Medical Report") ? "Available" : "Not Available"}
                                                </span>
                                            </TableCell>
                                            <TableCell className="py-1">
                                                {applicant && applicant.documents && applicant.documents.find(doc => doc.document_type === "Medical Report") ? (
                                                    <button
                                                        onClick={() => handleViewPassport(`${MySQLUrl}/${applicant.documents.find(doc => doc.document_type === "Medical Report").path}`)}
                                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                                    >
                                                        View Medical Report
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="bg-gray-400 text-gray-600 font-bold py-2 px-4 rounded cursor-not-allowed"
                                                        disabled
                                                    >
                                                        Medical Report Unavailable
                                                    </button>
                                                )}
                                            </TableCell>

                                        </TableRow>
                                        <TableRow className="py-0">
                                            <TableCell className="py-0">Visa</TableCell>
                                            <TableCell className="py-0">
                                                <span className={applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Visa") ? "text-green-700 font-bold" : "text-red-700 font-bold"}>
                                                    {applicant && applicant.documents && applicant.documents.some(doc => doc.document_type === "Visa") ? "Available" : "Not Available"}
                                                </span>
                                            </TableCell>
                                            <TableCell className="py-1">
                                                {applicant && applicant.documents && applicant.documents.find(doc => doc.document_type === "Visa") ? (
                                                    <button
                                                        onClick={() => handleViewPassport(`${MySQLUrl}/${applicant.documents.find(doc => doc.document_type === "Visa").path}`)}
                                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                                    >
                                                        View Visa
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="bg-gray-400 text-gray-600 font-bold py-2 px-4 rounded cursor-not-allowed"
                                                        disabled
                                                    >
                                                        Visa Unavailable
                                                    </button>
                                                )}
                                            </TableCell>


                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            </div>

                        </div>
                    </div>

                    <div className="h-7/12 mt-3 block">



                    </div>

                    <div className="bg-neutral-200 h-3/12"></div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default DetailsApplicant;