import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {HomeIcon, TrashIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import {PlusIcon} from "@heroicons/react/16/solid";
import {toast} from "react-toastify";
import axios from "axios";
import MySQLUrl from "../../DBConfig";

const NewApplicant = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [applicant, setApplicant] = useState([]);
    const [file, setFile] = useState(null);
    const [coverPhotoFile, setCoverPhotoFile] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState({
        ref:'',
        educationalQualifications: [''],
        salary: '',
        currency:'',
        contractPeriod: '',
        nationality: '',
        religion: '',
        birthPlace: '',
        livingTown: '',
        maritalStatus: '',
        noOfChildren: '',
        Weight: '',
        Height: '',
        Complexion: '',
        english: '',
        arabic: '',
        Driving: '',
        Sewing: '',
        cooking: '',
        washing: '',
        cleaning: '',
        baby_sitting: '',
        previousEmployments: [{ country: '', duration: '' }],
        Photo: '',
        coverPhoto: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    const getApplicant = localStorage.getItem('Applicant');

                    if (!getApplicant) {
                        navigate('/applicant');
                    }

                    const parsedApplicant = JSON.parse(getApplicant);
                    setApplicant(parsedApplicant);

                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();


    const handleFileChange = (event, setPreview, setFile, fileKey) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreview(reader.result);
            if (fileKey === 'Photo') {
                setFile(file); // Update file state variable for photo
            } else if (fileKey === 'coverPhoto') {
                setCoverPhotoFile(file); // Update coverPhotoFile state variable
            }
            setFormData(prevData => ({
                ...prevData,
                [fileKey]: file, // Update the corresponding property in formData
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePreviousEmploymentChange = (index, field, value) => {
        const updatedEmployments = [...formData.previousEmployments];
        updatedEmployments[index][field] = value;
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: updatedEmployments,
        }));
    };

    const handleAddPreviousEmployment = () => {
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: [...prevData.previousEmployments, { country: '', duration: '' }],
        }));
    };

    const handleRemovePreviousEmployment = (index) => {
        // Check if there's only one previous employment entry left
        if (formData.previousEmployments.length === 1) {
            toast.error("Fill at least one employment or use '-'")
            return;
        }

        // Remove the specified entry
        setFormData(prevData => ({
            ...prevData,
            previousEmployments: prevData.previousEmployments.filter((_, i) => i !== index),
        }));
    };


    const handleQualificationChange = (index, value) => {
        const updatedQualifications = [...formData.educationalQualifications];
        updatedQualifications[index] = value;
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: updatedQualifications,
        }));
    };

    const handleAddQualification = () => {
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: [...prevData.educationalQualifications, ''],
        }));
    };

    const handleRemoveQualification = (index) => {
        // Check if there's only one qualification entry left
        if (formData.educationalQualifications.length === 1) {
            toast.error("Fill at least one qualification or use '-'")
            return;
        }
        setFormData(prevData => ({
            ...prevData,
            educationalQualifications: prevData.educationalQualifications.filter((_, i) => i !== index),
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setLoading(true); // Set loading to true when form is submitted
        setDisabled(true); // Disable submit button

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            console.log(config);

            const formDataToSend = new FormData();

            const monthlySalary = `${formData.salary} ${formData.currency}`;
            formDataToSend.append('applicant_id', id);
            formDataToSend.append('refNo', formData.ref);
            formDataToSend.append('monthly_Salary', monthlySalary);
            formDataToSend.append('contractPeriod', formData.contractPeriod);
            formDataToSend.append('nationality', formData.nationality);
            formDataToSend.append('religion', formData.religion);
            formDataToSend.append('birthPlace', formData.birthPlace);
            formDataToSend.append('livingTown', formData.livingTown);
            formDataToSend.append('maritalStatus', formData.maritalStatus);
            formDataToSend.append('noOfChildren', formData.noOfChildren);
            formDataToSend.append('Weight', formData.Weight);
            formDataToSend.append('Height', formData.Height);
            formDataToSend.append('complexion', formData.Complexion);
            formDataToSend.append('english', formData.english);
            formDataToSend.append('arabic', formData.arabic);
            formDataToSend.append('Driving', formData.Driving);
            formDataToSend.append('Sewing', formData.Sewing);
            formDataToSend.append('cooking', formData.cooking);
            formDataToSend.append('washing', formData.washing);
            formDataToSend.append('cleaning', formData.cleaning);
            formDataToSend.append('baby_sitting', formData.baby_sitting);

            formData.educationalQualifications.forEach((qualification, index) => {
                formDataToSend.append(`educationalQualifications.qualification`, qualification);
            });


            formData.previousEmployments.forEach((employment, index) => {
                formDataToSend.append(`previousEmployments.country`, employment.country);
                formDataToSend.append(`previousEmployments.duration`, employment.duration);
            });


            // Append uploaded files
            formDataToSend.append('Photo', file);
            formDataToSend.append('coverPhoto', coverPhotoFile);


            console.log(formDataToSend);

            // Send form data
            const response = await axios.post(`${MySQLUrl}/cv/new`, formDataToSend, config);
            console.log('Response from the server:', response.data);

            const appId = response.data.newCV.id;

            setLoading(false); // Set loading to false when response is received
            setDisabled(false);

            // Clear form data and preview images
            setFormData({
                ref:'',
                salary: '',
                currency:'',
                contractPeriod: '',
                nationality: '',
                religion: '',
                birthPlace: '',
                livingTown: '',
                maritalStatus: '',
                noOfChildren: '',
                Weight: '',
                Height: '',
                Complexion: '',
                english: '',
                arabic: '',
                Driving: '',
                Sewing: '',
                cooking: '',
                washing: '',
                cleaning: '',
                baby_sitting: '',
                previousEmployments: [{ country: '', duration: '' }],
                educationalQualifications: [''],
                Photo: '',
                coverPhoto: '',
            });
            setPhotoPreview(null);
            setCoverPhotoPreview(null);
            setFile(null);
            toast.success("Successfully added applicant", {
                onClose: () => {
                    // Your onClose event handler logic goes here
                    navigate(`/applicant/details/${appId}`);
                }
            });



            // Optionally, you can redirect the user to a new page or show a success message
        } catch (error) {
            setLoading(false); // Set loading to false when error occurs
            setDisabled(false);
            toast.error('Error adding applicant');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }
    };




    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicants', url: `/applicant` },
        { label: `${applicant && applicant.applicant && applicant.applicant.fullName}`, url: '/applicant/details' },
        { label: `Add CV Details`, url: '/applicant/new-cv' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    return (
        <>
            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">
                <div className="h-[8vh] w-full px-4">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username}</strong> ({getRoleLabel(User.user_type)}) | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-200 h-[85vh] w-full px-4 block text-gray-800">
                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold  uppercase">Add</h1>

                    </div>

                    {/*<div className="px-16 py-6 rounded-lg bg-white mt-3">*/}



                    {/*</div>*/}

                    <div className="h-7/12 mt-3 block px-16 py-6 rounded-lg bg-white">

                        <form onSubmit={handleFormSubmit}>

                            {/* 1*/}

                            <div className="mb-4 grid grid-cols-2 gap-4">

                                <div className="mb-1 grid grid-cols-2 gap-4">



                                    <div>
                                        <label htmlFor="ref" className="block text-sm font-medium text-gray-600">
                                            Reference No
                                        </label>
                                        <input
                                            type="text"
                                            id="ref"
                                            name="ref"
                                            value={formData.ref}
                                            placeholder="Eg: #SA123"
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                            required
                                        />
                                        {formData.ref.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.ref.length >= 4 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}



                                    </div>


                                    <div className="flex flex-box">
                                        <div className="flex-grow mr-2">
                                            <label htmlFor="salary" className="block text-sm font-medium text-gray-600">
                                                Monthly Salary
                                            </label>
                                            <input
                                                type="text"
                                                id="salary"
                                                name="salary"
                                                value={formData.salary}
                                                onChange={handleInputChange}
                                                placeholder="Eg: 500"
                                                className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                required
                                            />
                                            {formData.salary.length === 0 && (
                                                <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                            )}
                                            {formData.salary.match(/[^0-9]/) && (
                                                <p className="text-red-500 text-xs italic">Please use only numerical characters.</p>
                                            )}
                                            {formData.salary.length >= 2 && !formData.salary.match(/[^0-9]/) && (
                                                <p className="text-green-500 text-xs italic">Looks Good</p>
                                            )}



                                        </div>


                                        <div className="">
                                            <label htmlFor="currency" className="block text-sm font-medium text-gray-600">
                                                Currency
                                            </label>
                                            <select
                                                id="currency"
                                                name="currency"
                                                value={formData.currency}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-md"
                                            >
                                                <option value="">Select Currency</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                                <option value="AED">AED</option>
                                                <option value="SAR">SAR</option>
                                                <option value="QAR">QAR</option>
                                                <option value="KWD">KWD</option>
                                                <option value="OMR">OMR</option>
                                                <option value="LKR">LKR</option>
                                                {/* Add more currency options as needed */}
                                            </select>

                                            {formData.currency === 0 && (
                                                <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                            )}
                                        </div>


                                    </div>

                                </div>

                                <div className="mb-4 grid grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="nationality" className="block text-sm font-medium text-gray-600">
                                            Nationality
                                        </label>
                                        <select
                                            id="nationality"
                                            name="nationality"
                                            value={formData.nationality}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        >
                                            <option value="">Select Country</option>
                                            <option value="Sri Lankan">Sri Lankan</option>
                                        </select>

                                    </div>
                                    <div>
                                        <label htmlFor="religion" className="block text-sm font-medium text-gray-600">
                                            Religion
                                        </label>
                                        <select
                                            id="religion"
                                            name="religion"
                                            value={formData.religion}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        >
                                            <option value="">Select Religion</option>
                                            <option value="Christianity">Christianity</option>
                                            <option value="Islam">Islam</option>
                                            <option value="Hinduism">Hinduism</option>
                                            <option value="Buddhism">Buddhism</option>

                                            {/* Add more options as needed */}
                                        </select>
                                    </div>

                                </div>


                            </div>


                            <div className="mb-4 grid grid-cols-2 gap-4">




                                <div className="mb-4 grid grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="contractPeriod" className="block text-sm font-medium text-gray-600">
                                            Contract Period
                                        </label>
                                        <select
                                            id="contractPeriod"
                                            name="contractPeriod"
                                            value={formData.contractPeriod}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        >
                                            <option >Select Contract Period</option>
                                            <option value={"1"}>One Year / 1 Year</option>
                                            <option value={"2"}>Two Years / 2 Years</option>
                                            <option value={"3"}>Three Years / 3 Years</option>
                                            <option value={"4"}>Four Years / 4 Years</option>
                                            <option value={"5"}>Five Years / 5 Years</option>
                                            <option value={"6"}>Six Years / 6 Years</option>

                                        </select>

                                        {formData.contractPeriod === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="birthPlace" className="block text-sm font-medium text-gray-600">
                                            Birth Place
                                        </label>
                                        <input
                                            type="text"
                                            id="birthPlace"
                                            name="birthPlace"
                                            value={formData.birthPlace}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            placeholder="Eg: Kurunegala"
                                            required
                                        />
                                        {formData.birthPlace.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.birthPlace.length >= 4 && (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}

                                    </div>



                                </div>

                                <div className="mb-4 grid grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="livingTown" className="block text-sm font-medium text-gray-600">
                                            Living Town
                                        </label>
                                        <input
                                            type="text"
                                            id="livingTown"
                                            name="livingTown"
                                            value={formData.livingTown}
                                            onChange={handleInputChange}
                                            placeholder="Eg: Kurunegala"
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        />

                                        {formData.livingTown.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.livingTown.length >= 4 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>


                                </div>


                            </div>



                            {/* 4 */}

                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="maritalStatus" className="block text-sm font-medium text-gray-600">
                                            Marital Status
                                        </label>
                                        <select
                                            id="maritalStatus"
                                            name="maritalStatus"
                                            value={formData.maritalStatus}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        >
                                            <option>Select Marital Status</option>
                                            <option value={"single"}>Single</option>
                                            <option value={"married"}>Married</option>
                                            <option value={"divorced"}>Divorced</option>
                                            <option value={"widowed"}>Widowed</option>

                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="noOfChildren" className="block text-sm font-medium text-gray-600">
                                            No Of Children
                                        </label>
                                        <input
                                            type="number"
                                            id="noOfChildren"
                                            name="noOfChildren"
                                            value={formData.noOfChildren}
                                            placeholder="Eg: 1"
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        />

                                        {formData.noOfChildren.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.noOfChildren.length >= 1 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>

                                </div>

                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="Weight" className="block text-sm font-medium text-gray-600">
                                            Weight (Kg)
                                        </label>
                                        <input
                                            type="number"
                                            id="Weight"
                                            name="Weight"
                                            placeholder="Eg: 75"
                                            value={formData.Weight}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        />
                                        {formData.Weight.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.Weight.length >= 1 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="Height" className="block text-sm font-medium text-gray-600">
                                            Height (cm)
                                        </label>
                                        <input
                                            type="text"
                                            id="Height"
                                            name="Height"
                                            placeholder="Eg: 165"
                                            value={formData.Height}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        />
                                        {formData.Height === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.Height >= 1 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>

                                </div>
                            </div>

                            {/* 5 */}

                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="Complexion" className="block text-sm font-medium text-gray-600">
                                            Complexion
                                        </label>
                                        <input
                                            type="text"
                                            id="Complexion"
                                            name="Complexion"
                                            placeholder="Eg: Fair"
                                            value={formData.Complexion}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                            required
                                        />

                                        {formData.Complexion.length === 0 && (
                                            <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                        )}
                                        {formData.Complexion.length >= 3 &&  (
                                            <p className="text-green-500 text-xs italic">Looks Good</p>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="english" className="block text-sm font-medium text-gray-600">
                                            English
                                        </label>
                                        <select
                                            id="english"
                                            name="english"
                                            value={formData.english}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select proficiency</option>
                                            <option value="excellent">Excellent</option>
                                            <option value="good">Good</option>
                                            <option value="fair">Fair</option>
                                            <option value="poor">Poor</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="arabic" className="block text-sm font-medium text-gray-600">
                                            Arabic
                                        </label>
                                        <select
                                            id="arabic"
                                            name="arabic"
                                            value={formData.arabic}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                        >
                                            <option value="">Select proficiency</option>
                                            <option value="excellent">Excellent</option>
                                            <option value="good">Good</option>
                                            <option value="fair">Fair</option>
                                            <option value="poor">Poor</option>
                                        </select>
                                    </div>

                                </div>
                            </div>



                            {/* 4 */}

                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <label htmlFor="Driving" className="block text-sm font-medium text-gray-600">
                                            Driving
                                        </label>
                                        <select
                                            id="Driving"
                                            name="Driving"
                                            value={formData.Driving}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"
                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>

                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="baby_sitting" className="block text-sm font-medium text-gray-600">
                                            Baby Sitting
                                        </label>
                                        <select
                                            id="baby_sitting"
                                            name="baby_sitting"
                                            value={formData.baby_sitting}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>
                                        </select>

                                    </div>
                                </div>

                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="Sewing" className="block text-sm font-medium text-gray-600">
                                            Sewing
                                        </label>
                                        <select
                                            id="Sewing"
                                            name="Sewing"
                                            value={formData.Sewing}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="cooking" className="block text-sm font-medium text-gray-600">
                                            Cooking
                                        </label>
                                        <select
                                            id="cooking"
                                            name="cooking"
                                            value={formData.cooking}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>
                                        </select>



                                    </div>
                                </div>
                            </div>


                            {/* 7 */}

                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="washing" className="block text-sm font-medium text-gray-600">
                                            Washing
                                        </label>
                                        <select
                                            id="washing"
                                            name="washing"
                                            value={formData.washing}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>
                                        </select>

                                    </div>
                                    <div>
                                        <label htmlFor="cleaning" className="block text-sm font-medium text-gray-600">
                                            Cleaning
                                        </label>
                                        <select
                                            id="cleaning"
                                            name="cleaning"
                                            value={formData.cleaning}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 w-full border rounded-md"

                                        >
                                            <option value="">Select Ability</option>
                                            <option value="can">Can</option>
                                            <option value="can't">Can't</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">


                                </div>
                            </div>



                            {/* 8 */}



                            <div>
                                {formData.educationalQualifications.map((qualification, index) => (
                                    <div key={index} className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                        <div>
                                            <label htmlFor={`educationalQualifications[${index}].qualification`} className="block text-sm font-medium text-gray-600">
                                                Education Qualification
                                            </label>
                                            <input
                                                type="text"
                                                id={`educationalQualifications[${index}].qualification`}
                                                placeholder="Passed O/L"
                                                name={`educationalQualifications[${index}].qualification`}
                                                value={qualification}
                                                onChange={(e) => handleQualificationChange(index, e.target.value)}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                required
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <button type="button" className="bg-red-500 hover:bg-red-900 text-white px-4 py-2 rounded-md flex mr-2" onClick={() => handleRemoveQualification(index)}>
                                                <TrashIcon className="h-[25px] mr-2" /> Remove
                                            </button>
                                            {index === formData.educationalQualifications.length - 1 && (
                                                <button type="button" className="bg-green-500 hover:bg-green-900 text-white px-4 py-2 rounded-md flex" onClick={handleAddQualification}>
                                                    <PlusIcon className="h-[25px] mr-2" /> Add Qualification
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}

                            </div>


                            {/* 9 */}

                            <div>
                                {formData.previousEmployments.map((employment, index) => (
                                    <div key={index} className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                                        <div>
                                            <label htmlFor={`previousEmployments[${index}].country`} className="block text-sm font-medium text-gray-600">
                                                Past Employment Country
                                            </label>
                                            <input
                                                type="text"
                                                id={`previousEmployments[${index}].country`}
                                                placeholder="Eg: Saudi Arabia"
                                                name={`previousEmployments[${index}].country`}
                                                value={employment.country}
                                                onChange={(e) => handlePreviousEmploymentChange(index, 'country', e.target.value)}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`previousEmployments[${index}].duration`} className="block text-sm font-medium text-gray-600">
                                                Past Employment Duration (Years)
                                            </label>
                                            <input
                                                type="number"
                                                id={`previousEmployments[${index}].duration`}
                                                name={`previousEmployments[${index}].duration`}
                                                placeholder="3"
                                                value={employment.duration}
                                                onChange={(e) => handlePreviousEmploymentChange(index, 'duration', e.target.value)}
                                                className="mt-1 p-2 w-full border rounded-md"
                                                required
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <button type="button" className="bg-red-500 hover:bg-red-900 text-white px-4 py-2 rounded-md flex mr-2" onClick={() => handleRemovePreviousEmployment(index)}>
                                                <TrashIcon className="h-[25px] mr-2" /> Remove
                                            </button>
                                            {index === formData.previousEmployments.length - 1 && (
                                                <button type="button" className="bg-green-500 hover:bg-green-900 text-white px-4 py-2 rounded-md flex" onClick={handleAddPreviousEmployment}>
                                                    <PlusIcon className="h-[25px] mr-2" /> Add Past Employment
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>




                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="Photo" className="block text-sm font-medium text-gray-600">
                                        Photo
                                    </label>
                                    <input
                                        type="file"
                                        id="Photo"
                                        name="Photo"
                                        onChange={(e) => handleFileChange(e, setPhotoPreview, setFile, 'Photo')}
                                        className="mt-1 p-2 w-full border rounded-md"
                                        accept="image/*" // Allow only image files
                                        required
                                    />
                                </div>
                                {/* Preview Image */}
                                {photoPreview && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-600">
                                            Preview Image
                                        </label>
                                        <img src={photoPreview} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                    </div>
                                )}
                            </div>

                            {/* Cover Photo Upload */}
                            <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="coverPhoto" className="block text-sm font-medium text-gray-600">
                                        Cover Photo
                                    </label>
                                    <input
                                        type="file"
                                        id="coverPhoto"
                                        name="coverPhoto"
                                        onChange={(e) => handleFileChange(e, setCoverPhotoPreview, setCoverPhotoFile, 'coverPhoto')}
                                        className="mt-1 p-2 w-full border rounded-md"
                                        accept="image/*" // Allow only image files
                                        required
                                    />
                                </div>
                                {/* Preview Cover Photo */}
                                {coverPhotoPreview && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-600">
                                            Preview Cover Photo
                                        </label>
                                        <img src={coverPhotoPreview} alt="Preview" className="border border-black p-4 w-auto h-[35vh]" />
                                    </div>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                disabled={disabled} // Disable button when disabled state is true
                            >
                                {loading ? (
                                    <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.481 0-4.736-.897-6.472-2.391l2.472-2.472 1.414 1.414-3.5 3.5zM20 12a8 8 0 11-16 0 8 8 0 0116 0z"></path>
                            </svg>
                            Submitting...
                        </span>
                                ) : (
                                    'Submit'
                                )}
                            </button>


                        </form>


                    </div>

                    <div className="bg-neutral-200 h-3/12"></div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default NewApplicant;