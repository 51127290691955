import { Document, Page } from 'react-pdf';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PdfViewer = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const location = useLocation();
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pdfUrl = queryParams.get('PDFurl');
        const decodedUrl = decodeURI(pdfUrl);
        const correctedUrl = decodedUrl.replace(/\\/g, '/'); // Replace all backslashes with forward slashes
        setPdfUrl(correctedUrl);
        console.log(correctedUrl)
    }, [location.search]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div>
            <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
        </div>
    );
};

export default PdfViewer;