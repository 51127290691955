import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import {List, ListItem, ListItemText, Pagination} from "@mui/material";
import Footer from "../../assets/components/common/footer";

const DetailsPassport = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [applicant, setApplicant] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    const getApplicant = localStorage.getItem('Passport');

                    if (!getApplicant) {
                        navigate('/passport');
                    }

                    const parsedApplicant = JSON.parse(getApplicant);
                    setApplicant(parsedApplicant);

                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Details', url: '/passport/details' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    return (
        <>
            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">
                <div className="h-[8vh] w-full px-4">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username}</strong> ({getRoleLabel(User.user_type)}) | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-200 h-[85vh] w-full px-4 block text-gray-800">
                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold  uppercase">{applicant && applicant.applicant && applicant.applicant.fullName}</h1>

                    </div>

                    <div className="px-16 py-6 rounded-lg bg-white mt-3">

                        <div>
                            <div>
                                <div className="justify-center items-center text-center">
                                    <h1 className="uppercase font-bold text-lg">
                                        Applicant Bio
                                    </h1>
                                </div>
                                <hr className="mt-2 mb-2"/>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>First Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.fName} </span>
                                    </div>

                                    <div>
                                        <span>Last Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.lName} </span>
                                    </div>


                                </div>

                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Full Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.fullName} </span>
                                    </div>

                                </div>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Applied Post: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.postApplied} </span>
                                    </div>

                                </div>

                                <div className="flex mt-2">
                                    {/*<span className="mr-1">Countries: </span>*/}
                                    {/*{applicant && applicant.applicant && applicant.applicant.countries.map((country, index) => (*/}
                                    {/*    <React.Fragment key={country.id}>*/}
                                    {/*        <span className="font-bold"> {country.country}</span>*/}
                                    {/*        {index !== applicant.applicant.countries.length - 1 && <span className="mx-1">|</span>}*/}
                                    {/*    </React.Fragment>*/}
                                    {/*))}*/}
                                </div>

                            </div>

                        </div>

                        <div>
                            <div>
                                <div className="justify-center items-center text-center">
                                    <h1 className="uppercase font-bold text-lg">
                                        Passport Info
                                    </h1>
                                </div>
                                <hr className="mt-2 mb-2"/>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Passport Number: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.passport && applicant.applicant.passport.passportNo} </span>
                                    </div>

                                </div>

                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Issued Place: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.passport && applicant.applicant.passport.issuedPlace} </span>
                                    </div>

                                </div>

                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Issued Date: </span>
                                        <span>Issued Date: </span>
                                        {applicant && applicant.applicant && applicant.applicant.passport && applicant.applicant.passport.issuedDate ?
                                            <span className="font-bold">
                                                {new Date(applicant.applicant.passport.issuedDate).toISOString().split('T')[0]}
                                            </span> :
                                            <span className="font-bold">-</span>
                                        }

                                    </div>

                                </div>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Expiry Date: </span>
                                        {applicant && applicant.applicant && applicant.applicant.passport && applicant.applicant.passport.expiryDate ?
                                            <span className="font-bold">
                                                {new Date(applicant.applicant.passport.expiryDate).toISOString().split('T')[0]}
                                            </span> :
                                            <span className="font-bold">-</span>
                                        }

                                    </div>

                                </div>
                            </div>

                        </div>



                        <div>
                            <div>
                                <div className="justify-center items-center text-center">
                                    <h1 className="uppercase font-bold text-lg">
                                        Guardian Info
                                    </h1>
                                </div>
                                <hr className="mt-2 mb-2"/>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>First Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.guardian && applicant.applicant.guardian.fNameGuardian} </span>
                                    </div>

                                    <div>
                                        <span>Last Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.guardian && applicant.applicant.guardian.lNameGuardian} </span>
                                    </div>


                                </div>

                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Full Name: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.guardian && applicant.applicant.guardian.fullNameGuardian} </span>
                                    </div>

                                </div>
                                <div className="flex justify-between mt-2">
                                    <div>
                                        <span>Applied Post: </span>
                                        <span className="font-bold">{applicant && applicant.applicant && applicant.applicant.guardian && applicant.applicant.guardian.guardianMobile} </span>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="h-7/12 mt-3 block">



                    </div>

                    <div className="bg-neutral-200 h-3/12"></div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default DetailsPassport;