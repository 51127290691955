import React, { useEffect, useState } from 'react';


import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import MySQLUrl from "../DBConfig";
import Sidebar from "../assets/components/common/sidepane";
import Home from "../pages/Home";
import NewPassportStepOne from "../pages/Passport/NewPassportStepOne";
import NewPassportStepTwo from "../pages/Passport/NewPassportStepTwo";
import NewPassportStepThree from "../pages/Passport/NewPassportStepThree";
import NewPassportSummary from "../pages/Passport/NewPassportSummary";
import ManagePassport from "../pages/Passport/ManagePassport";
import SearchPassport from "../pages/Passport/SearchPassport";
import DetailsPassport from "../pages/Passport/DetailsPassport";
import ManageEmployees from "../pages/Employee/ManageEmployees";
import NewEmployee from "../pages/Employee/NewEmployee";
import ManageApplicants from "../pages/CountryRepresentative/ManageApplicants";
import DetailsApplicant from "../pages/CountryRepresentative/DetailsApplicant";
import NewApplicant from "../pages/CountryRepresentative/NewApplicant";
import TestForm from "./testForm";
import DocumentUploadForm from "./testForm";
import CV from "../pages/CountryRepresentative/CV";
import PdfViewer from "../assets/components/common/PDFViewer";



const Dashboard = () => {

    const navigate = useNavigate();
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);




    return (
        <>


            <ToastContainer
                position="top-right" // Adjust position as needed
                hideProgressBar={true} // hide progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div className="h-screen max-h-screen flex flex-col bg-gray-900">


            <div className="flex-grow overflow-y-auto flex">

                <Sidebar user={User} />

                <div className="container p-0 text-light">
                    <Routes>
                        <Route exact path="/" element={<Home />} />

                        {/*    Passport*/}
                        <Route exact path="/passport/new/1" element={<NewPassportStepOne />} />
                        <Route exact path="/passport/new/2" element={<NewPassportStepTwo />} />
                        <Route exact path="/passport/new/3" element={<NewPassportStepThree />} />
                        <Route exact path="/passport/new/summary" element={<NewPassportSummary />} />

                        <Route exact path="/passport" user={User} element={<ManagePassport />} />
                        <Route exact path="/passport/search" user={User} element={<SearchPassport />} />
                        <Route exact path="/passport/details" user={User} element={<DetailsPassport />} />


                    {/*    Employee*/}
                        <Route exact path="/employee/new" user={User} element={<NewEmployee />} />
                        <Route exact path="/employees" user={User} element={<ManageEmployees />} />

                    {/*    Country Representative*/}
                        <Route exact path="/applicant" user={User} element={<ManageApplicants />} />
                        {/*<Route exact path="/applicant?country=:country" user={User} element={<ManageApplicants />} />*/}
                        <Route exact path="/applicant/details/:id" user={User} element={<DetailsApplicant />} />
                        <Route exact path="/applicant/new-cv/:id" user={User} element={<NewApplicant />} />
                        <Route exact path="/applicant/cv/:applicantId" user={User} element={<CV />} />
                        <Route exact path="/applicant/cv/pdf-viewer" user={User} element={<PdfViewer />} />


                        <Route exact path="/test" user={User} element={<DocumentUploadForm />} />

                    </Routes>
                </div>

            </div>




        </div>
        </>
    );
};

export default Dashboard;