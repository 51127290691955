import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import MySQLUrl from "../../DBConfig";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import { List, ListItem, ListItemText, Pagination } from '@mui/material';

const SearchPassport = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [applicant, setApplicant] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [resultCount, setResultCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Search', url: '/passport/search' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const handleSearch = async () => {
        try {
            setLoading(true);

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const response = await axios.get(`${MySQLUrl}/applicant/search?query=${searchQuery}`, config);
            setSearchResults(response.data);
            setResultCount(response.data.length);
            // console.log(response.data);

        } catch (error) {
            console.error('Error searching applicants:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleView = (result) => {
        console.log(result);
        localStorage.setItem('Passport', JSON.stringify(result));
        navigate('/passport/details');

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const indexOfLastRow = page * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = searchResults.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <>
            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">
                <div className="h-[8vh] w-full px-4">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username}</strong> ({getRoleLabel(User.user_type)}) | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-200 h-[85vh] w-full px-4 block">
                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold text-gray-800 uppercase">SEARCH APPLICANTS</h1>
                    </div>

                    <div className="h-7/12 mt-3 block">

                        <div className="flex justify-center items-center">

                            <div className="flex items-center justify-center w-6/12">
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded-l-full px-4 py-2 focus:outline-none text-gray-800 focus:border-blue-500 w-full "
                                    placeholder="Enter Passport No. / Name / Guardian Name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-r-lg"
                                    onClick={handleSearch}
                                    disabled={loading || disabled}
                                >
                                    Search
                                </button>
                            </div>

                        </div>

                        <div className="flex justify-center items-center">

                            <div className="w-10/12">
                                <div className="mt-2 text-gray-800">
                                    <h5 className="font-semibold">Search results found: {resultCount}</h5>


                                    <div className="">
                                        <List>
                                            {currentRows.map((result) => (
                                                <ListItem className="bg-neutral-100 mt-1" key={result.applicant.id}>
                                                    <ListItemText
                                                        primary={result.applicant.fullName}
                                                        secondary={
                                                            <React.Fragment>
                                                                <span>Passport No.: {result.applicant.passport.passportNo}</span>
                                                                <br />
                                                                <span>Guardian: {result.applicant.guardian.fullNameGuardian}</span>
                                                                <br />
                                                                <span>Guardian Contact: {result.applicant.guardian.guardianMobile}</span>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <button
                                                        onClick={() => handleView(result)}
                                                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full bottom-4 right-4">
                                                        View
                                                    </button>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>



                                </div>

                                <div className="mt-3 flex justify-center items-center">
                                    <Pagination
                                        count={Math.ceil(resultCount / rowsPerPage)}
                                        page={page}
                                        onChange={handleChangePage}
                                        color="primary"
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="bg-neutral-200 h-3/12"></div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default SearchPassport;