import React from "react";
import {Link, useNavigate} from 'react-router-dom';
import {
    Accordion,
    AccordionBody, AccordionHeader,
    Card,
    List,
    ListItem,
    ListItemPrefix, Typography,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon,
    BookOpenIcon,
    EnvelopeIcon,
    CurrencyRupeeIcon,
    CurrencyDollarIcon,
    NewspaperIcon,
    ChevronDownIcon,
    ChevronRightIcon, UserGroupIcon, DocumentMagnifyingGlassIcon, TrashIcon, MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";



const Sidebar = ({ user }) => {

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(0);

    const handleLinkClick = (country) => {
        navigate(`/applicant?country=${country}`);
        window.location.href = window.location.href;
        // This will trigger a refresh because the URL has changed
    };

    const renderPassportManagementItem = () => {
        if (user && user.user_type === 1  ){
            return (
                <>
                    <Link to="/passport/search">
                        <ListItem className="hover:bg-blue-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <MagnifyingGlassIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Passport Search
                        </ListItem>
                    </Link>

                    <Link to="/passport/new/1">
                        <ListItem className="hover:bg-green-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <BookOpenIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Passport Registration
                        </ListItem>
                    </Link>

                    <Link to="/passport">
                        <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <DocumentMagnifyingGlassIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Passport Management
                        </ListItem>
                    </Link>

                    <Link to="/">
                        <ListItem className="hover:bg-red-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <TrashIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Bin
                        </ListItem>
                    </Link>

                </>
            );
        }
        return null;
    };


    const renderAccountsItem = () => {
        if (user && (user.user_type === 2 || user.user_type === 0)) {
            return (
                <Link to="/">
                    <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                        <ListItemPrefix>
                            <CurrencyDollarIcon className="h-5 w-5 mr-3" />
                        </ListItemPrefix>
                        Accounts
                    </ListItem>
                </Link>
            );
        }
        return null;
    };

    const renderCountryItem = () => {
        if (user && (user.user_type === 4)) {

            const country = user.responsibility.toLowerCase();
            return (
                <>
                    <Link to={`/applicant/search`}>
                        <ListItem className="hover:bg-blue-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <MagnifyingGlassIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Passport Search
                        </ListItem>
                    </Link>

                    <Link to="/applicant/new/1">
                        <ListItem className="hover:bg-green-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <BookOpenIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Passport Registration
                        </ListItem>
                    </Link>

                    <Link to={`/applicant`}>
                        <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <DocumentMagnifyingGlassIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Applicant Management
                        </ListItem>
                    </Link>

                    <Link to="/">
                        <ListItem className="hover:bg-red-500 hover:text-black py-2">
                            <ListItemPrefix>
                                <TrashIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Bin
                        </ListItem>
                    </Link>

                </>
            );
        }
        return null;
    };


    const renderDirectorEmploymentItem = () => {
        if (user.user_type === 0) {
            return (

                <>
                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4  transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0 hover:bg-white/60 backdrop-blur-lg hover:text-black" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 py-2 justify-content-between">
                            <ListItemPrefix>
                                <BookOpenIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto ">
                                Passport
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block text-neutral-200">
                            <div className="px-3">
                                <Link to="/passport">
                                    <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <DocumentMagnifyingGlassIcon className="h-5 w-5 mr-3" />
                                        </ListItemPrefix>
                                        Management
                                    </ListItem>
                                </Link>

                                <Link to="/passport/search">
                                    <ListItem className="hover:bg-blue-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <MagnifyingGlassIcon className="h-5 w-5 mr-3" />
                                        </ListItemPrefix>
                                        Search
                                    </ListItem>
                                </Link>
                                <Link to="/passport/new/1">
                                    <ListItem className="hover:bg-green-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <BookOpenIcon className="h-5 w-5 mr-3" />
                                        </ListItemPrefix>
                                        Registration
                                    </ListItem>
                                </Link>

                                <Link to="/">
                                    <ListItem className="hover:bg-red-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <TrashIcon className="h-5 w-5 mr-3" />
                                        </ListItemPrefix>
                                        Bin
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </AccordionBody>
                </Accordion>

                <Accordion
                    open={open === 2}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4  transition-transform ${open === 2 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <ListItem className="p-0 hover:bg-yellow-500 hover:text-black" selected={open === 2}>
                        <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3 py-2 justify-content-between">
                            <ListItemPrefix>
                                <NewspaperIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal ">
                                Employment
                            </Typography>
                        </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 ">
                        <List className="p-0 block text-neutral-200">
                            <div className="px-3">
                                {/*<Link to="/applicant?country=kuwait">*/}
                                <ListItem onClick={() => handleLinkClick('kuwait')} className="hover:bg-yellow-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        Kuwait
                                    </ListItem>
                                {/*</Link>*/}
                                {/*<Link to="/applicant?country=saudi">*/}
                                <ListItem onClick={() => handleLinkClick('saudi')} className="hover:bg-yellow-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        Saudi Arabia
                                    </ListItem>
                                {/*</Link>*/}
                                {/*<Link to="/applicant?country=uae">*/}
                                <ListItem onClick={() => handleLinkClick('uae')} className="hover:bg-yellow-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        UAE
                                    </ListItem>
                                {/*</Link>*/}
                                {/*<Link to="/applicant?country=qatar">*/}
                                <ListItem onClick={() => handleLinkClick('qatar')} className="hover:bg-yellow-500 hover:text-black py-2">
                                        <ListItemPrefix>
                                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                        </ListItemPrefix>
                                        Qatar
                                    </ListItem>
                                {/*</Link>*/}
                            </div>
                        </List>
                    </AccordionBody>
                </Accordion>
                </>
            );
        }
        return null;
    };


    const renderEmployeeManagement = () => {
        if (user.user_type === 0) {
            return (
                <Link to="/employees">
                    <ListItem className="hover:bg-white/60 backdrop-blur-lg hover:text-black py-2">
                        <ListItemPrefix>
                            <UserGroupIcon className="h-5 w-5 mr-3" />
                        </ListItemPrefix>
                        Employees
                    </ListItem>
                </Link>
            );
        }
        return null;
    };

    const renderSMSItem = () => {
        if (user.user_type === 0) {
            return (
                <Link to="/">
                    <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                        <ListItemPrefix>
                            <EnvelopeIcon className="h-5 w-5 mr-3" />
                        </ListItemPrefix>
                        SMS
                    </ListItem>
                </Link>
            );
        }
        return null;
    };


    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const handleLogout = () => {

        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        // Redirect to the login page
        navigate('/login');
    };




    return (
        <aside className={"w-1/5 h-full  shadow-xl shadow-blue-gray-900/5  overflow-y-auto"}>
            <Card className="p-4 px-1 bg-blue-950 h-full text-neutral-200">
                <List className="block">
                        <ListItem className="justify-center items-center">
                            <img src={"https://sana.lk/wp-content/uploads/2024/02/sana-logo-e1710306680781.jpg"}
                                 className="h-[80px] w-[100px]"
                                 alt={"Sana logo"}/>
                        </ListItem>
                    <Link to="/" >
                        <ListItem className="hover:bg-white/50 backdrop-blur-md hover:text-black py-2">
                            <ListItemPrefix>
                                <InboxIcon className="h-5 w-5 mr-3" />
                            </ListItemPrefix>
                            Dashboard
                        </ListItem>
                    </Link>


                    {renderPassportManagementItem()}

                    {renderDirectorEmploymentItem()}

                    {renderCountryItem()}

                    {renderAccountsItem()}

                    {renderEmployeeManagement()}

                    {renderSMSItem()}


                    <hr className="my-2 border-blue-gray-50" />


                    <ListItem className="hover:bg-yellow-500 hover:text-black py-2">
                        <ListItemPrefix>
                            <UserCircleIcon className="h-5 w-5 me-3" />
                        </ListItemPrefix>
                        Profile
                    </ListItem>

                    <Link to="/settings">
                        <ListItem className="hover:bg-yellow-500 hover:text-black py-2">

                            <ListItemPrefix>
                                <Cog6ToothIcon className="h-5 w-5 me-3" />
                            </ListItemPrefix>
                            Settings

                        </ListItem>
                    </Link>


                    <ListItem onClick={handleLogout} className="hover:bg-yellow-500 hover:text-black py-2">
                        <ListItemPrefix>
                            <PowerIcon className="h-5 w-5 me-3" />
                        </ListItemPrefix>
                        Log Out
                    </ListItem>
                </List>
            </Card>
        </aside>
    );
};
export default Sidebar;