import React from 'react';
import Sidebar from "./assets/components/common/sidepane";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Registration from "./view/Registration";
import Login from "./view/Login";
import Dashboard from "./view/Dashboard";

const App = () => {
    return (
        <div className="h-[100vh] max-h-[100vh] w-[100wv] bg-gray-500">
            <Router>
                <Routes>

                    <Route exact path="/*" element={<Dashboard />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/registration" element={<Registration />} />

                </Routes>

            </Router>

        </div>
    );
};

export default App;