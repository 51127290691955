import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import {useNavigate} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";

import { ReactTags } from 'react-tag-autocomplete';
import '../../assets/css/react-tags.css';
import { suggestions } from '../../assets/components/common/countries';

const NewPassportStepOne = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [selected, setSelected] = useState([]);

    const [formData, setFormData] = useState({
        fName:'',
        lName:'',
        fullName:'',
        postApplied:'',
        countries:[''],
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Register', url: '/passport/new' },
    ];

    // Function to handle adding a country tag
    // Function to handle adding a country tag
    const handleAdd = (tag) => {
        setSelected([...selected, tag]);
        setFormData((prevData) => ({
            ...prevData,
            countries: [...prevData.countries, tag.label] // Append the new country label to the existing array
        }));
    };


    // Function to handle deleting a country tag
    const handleDelete = (i) => {
        const newSelected = selected.slice();
        newSelected.splice(i, 1);
        setSelected(newSelected);

        const newCountries = formData.countries.slice();
        newCountries.splice(i, 1);
        setFormData({ ...formData, countries: newCountries }); // Update formData with the deleted country
    };

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Collect form data
        const formData = {
            fName: e.target.fName.value,
            lName: e.target.lName.value,
            fullName: e.target.fullName.value,
            postApplied: e.target.postApplied.value,
            countries: selected.map(tag => tag.label).join(',')  // Extract country labels from selected tags
        };

        // Store form data in localStorage
        localStorage.setItem('formDataStepOne', JSON.stringify(formData));

        // Redirect to the next step
        navigate('/passport/new/2'); // Replace '/next-step' with the actual URL of the next step
    };


    return (
        <>

            <div className="block h-[100vh] bg-neutral-200">

                    <div className="h-[8vh] w-full px-4">

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>

                                <div className="mt-3 relative text-gray-700">
                                    {User.length === 0 ? (
                                            <div className="absolute right-0">
                                                Welcome, Loading...
                                            </div>
                                        ):
                                        (
                                            <div className="absolute right-0 capitalize">
                                                Welcome, <strong>{User.username} ({getRoleLabel(User.user_type)})</strong> | {formattedTime}
                                            </div>)}
                                </div>


                            </div>
                        </div>




                    </div>

                    <div className="bg-neutral-200 h-[85vh] w-full px-4 block">

                        <div className="h-3/12 justify-center text-center">
                                <h1 className="text-2xl font-bold text-gray-800 uppercase">Start registering new applicant</h1>

                        </div>


                        <div className="h-7/12 block">

                            <div className="flex justify-center mt-3">

                                <div className="flex items-center">
                                    <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                        <span className="text-gray-100">1</span>
                                    </div>
                                    <span className="text-gray-600 ml-2 font-bold">Applicant Info</span>
                                </div>


                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-gray-600 rounded-full">
                                        <span className="text-gray-100">2</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Applicant Passport</span>
                                </div>

                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-gray-600 rounded-full">
                                        <span className="text-gray-100">3</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Guardian Info</span>
                                </div>

                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-gray-600 rounded-full">
                                        <span className="text-gray-100">4</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Applicant Overview</span>
                                </div>
                            </div>



                            <div className="mt-3">

                                <div className="flex items-center justify-center ">
                                    <div className="w-8/12 bg-white items-center justify-center px-4 py-6 rounded-lg">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="fName" className="block text-sm font-medium text-gray-600">
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fName"
                                                    name="fName"
                                                    value={formData.fName}
                                                    placeholder="Eg: John "
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>

                                            <div>
                                                <label htmlFor="lName" className="block text-sm font-medium text-gray-600">
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="lName"
                                                    name="lName"
                                                    value={formData.lName}
                                                    placeholder="Eg: Doe"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>



                                        </div>

                                        <div className="mb-4 grid grid-cols-1 gap-4">
                                            <div>
                                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fullName"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    placeholder="Eg: John Doe"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>


                                        </div>


                                        <div className="mb-4 grid grid-cols-2 gap-4">

                                            <div>
                                                <label htmlFor="postApplied" className="block text-sm font-medium text-gray-600">
                                                    Post Applied
                                                </label>
                                                <input
                                                    type="text"
                                                    id="postApplied"
                                                    name="postApplied"
                                                    value={formData.postApplied}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border text-gray-800 rounded-md"
                                                    placeholder="Eg: House Cleaner"
                                                    required
                                                />


                                            </div>



                                        </div>


                                        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="text-gray-800">
                                                <label htmlFor="countries" className="block text-sm font-medium text-gray-600">
                                                    Select Countries (You can select multiple countries)
                                                </label>

                                                <ReactTags
                                                    id={"countries"}
                                                    name={"countries"}
                                                    labelText="Select countries"
                                                    selected={selected}
                                                    suggestions={suggestions}
                                                    onAdd={handleAdd}
                                                    onDelete={handleDelete}
                                                    noOptionsText="No matching countries"

                                                />



                                            </div>

                                        </div>


                                        <div className="flex justify-end items-center">
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-900 px-8 py-2 rounded-lg">
                                                Next >
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>





                        </div>

                        <div className="bg-neutral-200 h-3/12">


                        </div>

                    </div>

                <div>
                    <Footer/>
                </div>





            </div>

        </>
    );
};

export default NewPassportStepOne;