import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { HomeIcon } from '@heroicons/react/24/solid';
import Breadcrumb from '../../assets/components/common/breadcrumb';
import Footer from '../../assets/components/common/footer';
import MySQLUrl from "../../DBConfig";

const ManagePassport = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [applicant, setApplicant] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser);
                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    const config = {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    };

                    const response = await axios.get(`${MySQLUrl}/applicant`, config);
                    setApplicant(response.data);
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Register', url: '/passport/new' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const handleRowSelect = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    const handleView = (result) => {
        // console.log(result)
        const arrangedData = {
            applicant: {
                ...result.applicant,
                passport: result.passport,
                guardian: result.guardian,
                countries: result.countries,
            }
        };
        localStorage.setItem('Passport', JSON.stringify(arrangedData));
        navigate('/passport/details');

    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleSort = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortedField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };


    const filteredData = applicant.filter((app) => {
        return app.applicant.fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const sortedData = sortedField
        ? filteredData.slice().sort((a, b) => {
            const fieldA = a.applicant.fullName ?? '';
            const fieldB = b.applicant.fullName ?? '';
            const comparison = fieldA.localeCompare(fieldB);
            return sortDirection === 'asc' ? comparison : -comparison;
        })
        : filteredData;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className="block h-[93vh] bg-neutral-200 overflow-y-auto">
                <div className="h-[8vh] w-full px-4">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{User.username}</strong> ({getRoleLabel(User.user_type)}) | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-200 h-[85vh] w-full px-4 block">
                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold text-gray-800 uppercase">Manage applicants and passports</h1>
                    </div>
                    <div className="h-7/12 block mt-3">
                        <TextField
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            size="small"
                            className="mb-4"
                        />
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedRows.length === sortedData.length} // Check if all rows are selected
                                                onChange={(event) => {
                                                    const newSelected = selectedRows.length === sortedData.length ? [] : sortedData.map(app => app.applicant.id);
                                                    setSelectedRows(newSelected);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortedField === 'sortedData.applicant.fullName'}
                                                direction={sortDirection}
                                                onClick={() => handleSort('sortedData.applicant.fullName')}
                                            >
                                                <strong>Applicant Name</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell><strong>Post Applied</strong></TableCell>
                                        <TableCell><strong>Passport Number</strong></TableCell>
                                        <TableCell><strong>Issued Place</strong></TableCell>
                                        <TableCell><strong>Issued Date</strong></TableCell>
                                        <TableCell><strong>Expiry Date</strong></TableCell>
                                        <TableCell><strong>Guardian Name</strong></TableCell>
                                        <TableCell><strong>Guardian Mobile</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((app) => (
                                        <TableRow className="hover:bg-gray-200" key={app.applicant.id}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(app.applicant.id)}
                                                    onChange={(event) => handleRowSelect(event, app.applicant.id)}
                                                />
                                            </TableCell>

                                            <TableCell
                                                onClick={() => handleView(app)}
                                                className="hover:text-blue-800 hover:underline hover:cursor-pointer">{app.applicant.fullName}</TableCell>

                                            <TableCell>{app.applicant && app.applicant.postApplied}</TableCell>
                                            <TableCell>{app.passport && app.passport.passportNo}</TableCell>
                                            <TableCell>{app.passport && app.passport.issuedPlace}</TableCell>
                                            <TableCell>{app.passport && new Date(app.passport.issuedDate).toLocaleDateString()}</TableCell>
                                            <TableCell>{app.passport && new Date(app.passport.expiryDate).toLocaleDateString()}</TableCell>
                                            <TableCell>{app.guardian && app.guardian.fullNameGuardian}</TableCell>
                                            <TableCell>{app.guardian && app.guardian.guardianMobile}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={sortedData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </div>
                    <div className="bg-neutral-200 h-3/12"></div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default ManagePassport;
