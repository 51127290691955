import React, { useState } from 'react';
import axios from 'axios';
import MySQLUrl from "../DBConfig";

const DocumentUploadForm = () => {
    const [applicantId, setApplicantId] = useState('');
    const [file, setFile] = useState(null);
    const [documentType, setDocumentType] = useState('');

    const handleInputChange = (e) => {
        setApplicantId(e.target.value);
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleDocumentTypeChange = (e) => {
        setDocumentType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!applicantId || !file || !documentType) {
            console.error('Applicant ID, Document Type, and File are required');
            return;
        }

        const formData = new FormData();
        formData.append('applicantId', applicantId);
        formData.append('file', file);
        formData.append('documentType', documentType);

        try {
            const response = await axios.post(`${MySQLUrl}/document/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            // Reset form after successful submission
            setApplicantId('');
            setFile(null);
            setDocumentType('');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="text-gray-800">
            <h2>Document Upload Form</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="applicantId">Applicant ID:</label>
                    <input type="text" id="applicantId" value={applicantId} onChange={handleInputChange} />
                </div>
                <div>
                    <label htmlFor="file">Select PDF File:</label>
                    <input type="file" id="file" onChange={handleFileInputChange} />
                </div>
                <div>
                    <label htmlFor="documentType">Document Type:</label>
                    <input
                        type="text"
                        id="documentType"
                        value={documentType}
                        onChange={handleDocumentTypeChange}
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default DocumentUploadForm;
