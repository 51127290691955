import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import axios from "axios";
import MySQLUrl from "../../DBConfig";
import {toast} from "react-toastify";

const NewEmployee = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [formData, setFormData] = useState({
        username: '', // Add username field if it's not already in your state
        email: '', // Add email field if it's not already in your state
        user_type: '', // Add user_type field if it's not already in your state
        responsibility: '', // Add responsibility field if it's not already in your state
        password: '', // Add password field
        confirmPassword: '', // Add confirmPassword field
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Employees', url: '/employees' },
        { label: 'Register', url: '/employee/new' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true); // Set loading to true when form is submitted
        setDisabled(true); // Disable submit button

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            console.log('FormData:', formData);

            const response = await axios.post(`${MySQLUrl}/user/new`, formData, config);
            // console.log('Response from the server:', response.data);
            toast.success("Employee added successfully");


        } catch (error) {
            setLoading(false); // Set loading to false when error occurs
            setDisabled(false);
            toast.error('Error adding employee');
            console.error('Error submitting form:', error);
            // Handle error, show an error message, etc.
        }

        // Redirect to the next step
        navigate('/employees'); // Replace '/next-step' with the actual URL of the next step
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = () => {
        // Add your password validation logic here
        // For example, check if password is at least 8 characters long
        return formData.password.length >= 8;
    };

    const validateConfirmPassword = () => {
        // Add your confirm password validation logic here
        // For example, check if confirmPassword matches password
        return formData.password === formData.confirmPassword;
    };


    return (
        <>

            <div className="block h-[100vh] bg-neutral-200">

                <div className="h-[8vh] w-full px-4">

                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>

                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ):
                                    (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} ({getRoleLabel(User.user_type)})</strong> | {formattedTime}
                                        </div>)}
                            </div>


                        </div>
                    </div>




                </div>

                <div className="bg-neutral-200 h-[85vh] w-full px-4 block">

                    <div className="h-3/12 justify-center text-center">
                        <h1 className="text-2xl font-bold text-gray-800 uppercase">Start registering new employee</h1>

                    </div>


                    <div className="h-7/12 block">

                        <div className="flex justify-center mt-3">

                            <div className="flex items-center">
                                <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                    <span className="text-gray-100">1</span>
                                </div>
                                <span className="text-gray-600 ml-2 font-bold">Employee Info</span>
                            </div>

                        </div>



                        <div className="mt-3">

                            <div className="flex items-center justify-center ">
                                <div className="w-8/12 bg-white items-center justify-center px-4 py-6 rounded-lg">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="username" className="block text-sm font-medium text-gray-600">
                                                    Username
                                                </label>
                                                <input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={formData.username}
                                                    placeholder="Eg: John"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>

                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    placeholder="Eg: john@sana.lk"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>



                                        </div>

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="user_type" className="block text-sm font-medium text-gray-600">
                                                    User Type
                                                </label>
                                                <select
                                                    id="user_type"
                                                    name="user_type"
                                                    value={formData.user_type}
                                                    placeholder="Eg: John Doe"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                >
                                                    <option value="">Select a user type</option>
                                                    <option value="0">Manager</option>
                                                    <option value="1">Passport</option>
                                                    <option value="2">Accounts</option>
                                                    <option value="3">Complaints</option>
                                                    <option value="4">Country Representative</option>

                                                </select>



                                            </div>

                                            <div>
                                                <label htmlFor="responsibility" className="block text-sm font-medium text-gray-600">
                                                    User Country
                                                </label>
                                                <select
                                                    id="responsibility"
                                                    name="responsibility"
                                                    value={formData.responsibility}
                                                    placeholder="Eg: Kuwait"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md text-gray-800 focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                >
                                                    <option value="ALL">Select a Country</option>
                                                    <option value="ALL">ALL</option>
                                                    <option value="KUWAIT">Kuwait</option>
                                                    <option value="SAUDI">Saudi Arabia</option>
                                                    <option value="OMAN">Oman</option>
                                                    <option value="UAE">UAE</option>
                                                    <option value="QATAR">Qatar</option>

                                                </select>

                                            </div>


                                        </div>


                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                                                    Password
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        id="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                        className="mt-1 p-2 w-full border text-gray-800 rounded-md"
                                                        placeholder="Should be at least 8 characters long"
                                                        required
                                                    />
                                                    <button
                                                        type="button"
                                                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        <svg
                                                            className="h-5 w-5 text-gray-500"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            {showPassword ? (
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                                                                    clipRule="evenodd"
                                                                />
                                                            ) : (
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 4a6 6 0 100 12 6 6 0 000-12zM2 10a8 8 0 1116 0 8 8 0 01-16 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            )}
                                                        </svg>
                                                    </button>
                                                </div>
                                                {!validatePassword() && (
                                                    <p className="text-red-500 text-xs mt-1">Password must be at least 8 characters long</p>
                                                )}
                                            </div>
                                            <div>
                                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border text-gray-800 rounded-md"
                                                    placeholder="Type the same password"
                                                    required
                                                />
                                                {!validateConfirmPassword() && (
                                                    <p className="text-red-500 text-xs mt-1">Passwords do not match</p>
                                                )}
                                            </div>
                                        </div>


                                        <div className="flex justify-end items-center">
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-900 px-8 py-2 rounded-lg">
                                                Next >
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>





                    </div>

                    <div className="bg-neutral-200 h-3/12">


                    </div>

                </div>

                <div>
                    <Footer/>
                </div>





            </div>

        </>
    );
};

export default NewEmployee;