import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../assets/components/common/breadcrumb";
import Footer from "../../assets/components/common/footer";
import {useNavigate} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";

const NewPassportStepTwo = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [formData, setFormData] = useState({
        passportNo:'',
        issuedPlace:'',
        issuedDate:'',
        expiryDate:'',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                    // Retrieve form data from local storage
                    const storedFormData = localStorage.getItem('formDataStepOne');
                    if (storedFormData) {
                        const parsedFormData = JSON.parse(storedFormData);
                        setFormData(parsedFormData);
                    } else {
                        navigate('/passport')
                    }

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Passport', url: '/passport' },
        { label: 'Register', url: '/passport/new' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Collect form data
        const formData = {
            passportNo: e.target.passportNo.value,
            issuedPlace: e.target.issuedPlace.value,
            issuedDate: e.target.issuedDate.value,
            expiryDate: e.target.expiryDate.value,
        };

        // Store form data in localStorage
        localStorage.setItem('formDataStepTwo', JSON.stringify(formData));

        // Redirect to the next step
        navigate('/passport/new/3');
    };


    return (
        <>

            <div className="block h-[100vh] bg-neutral-200">

                    <div className="h-[8vh] w-full px-4">

                        <div className="col-span-12 mt-0">
                            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                                <div className="mt-3">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>

                                <div className="mt-3 relative text-gray-700">
                                    {User.length === 0 ? (
                                            <div className="absolute right-0">
                                                Welcome, Loading...
                                            </div>
                                        ):
                                        (
                                            <div className="absolute right-0 capitalize">
                                                Welcome, <strong>{User.username} ({getRoleLabel(User.user_type)})</strong> | {formattedTime}
                                            </div>)}
                                </div>


                            </div>
                        </div>




                    </div>

                    <div className="bg-neutral-200 h-[85vh] w-full px-4 block">

                        <div className="h-3/12 justify-center text-center">
                                <h1 className="text-2xl font-bold text-gray-800 uppercase">continue registering applicant</h1>

                        </div>


                        <div className="h-7/12 block">

                            <div className="flex justify-center mt-3">

                                <div className="flex items-center">
                                    <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                        <span className="text-gray-100">1</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Applicant Info</span>
                                </div>


                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-blue-600 rounded-full">
                                        <span className="text-gray-100">2</span>
                                    </div>
                                    <span className="text-gray-600 ml-2 font-bold">Applicant Passport</span>
                                </div>

                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-gray-600 rounded-full">
                                        <span className="text-gray-100">3</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Guardian Info</span>
                                </div>

                                <div className="flex items-center ml-8">
                                    <div className="w-12 h-12 flex items-center justify-center bg-gray-600 rounded-full">
                                        <span className="text-gray-100">4</span>
                                    </div>
                                    <span className="text-gray-600 ml-2">Applicant Overview</span>
                                </div>
                            </div>



                            <div className="mt-3">

                                <div className="flex items-center justify-center text-gray-800">
                                    <div className="w-8/12 bg-white items-center justify-center px-4 py-6 rounded-lg">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="passportNo" className="block text-sm font-medium text-gray-600">
                                                    Passport Number
                                                </label>
                                                <input
                                                    type="text"
                                                    id="passportNo"
                                                    name="passportNo"
                                                    value={formData.passportNo}
                                                    placeholder="Eg: N84759248 "
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>

                                            <div>
                                                <label htmlFor="issuedPlace" className="block text-sm font-medium text-gray-600">
                                                    Issued Place
                                                </label>
                                                <input
                                                    type="text"
                                                    id="issuedPlace"
                                                    name="issuedPlace"
                                                    value={formData.issuedPlace}
                                                    placeholder="Eg: Colombo"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>



                                        </div>

                                        <div className="mb-4 grid grid-cols-1 gap-4">
                                            <div>
                                                <label htmlFor="issuedDate" className="block text-sm font-medium text-gray-600">
                                                    Issued Date
                                                </label>
                                                <input
                                                    type="date"
                                                    id="issuedDate"
                                                    name="issuedDate"
                                                    value={formData.issuedDate}
                                                    placeholder="Eg: 03/14/2024"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>

                                            <div>
                                                <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-600">
                                                    Expiry Date
                                                </label>
                                                <input
                                                    type="date"
                                                    id="expiryDate"
                                                    name="expiryDate"
                                                    value={formData.expiryDate}
                                                    placeholder="Eg: 03/14/2034"
                                                    onChange={handleInputChange}
                                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                                    required
                                                />



                                            </div>


                                        </div>


                                        <div className="flex justify-end items-center text-gray-100">
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-900 px-8 py-2 rounded-lg">
                                                Next >
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </div>





                        </div>

                        <div className="bg-neutral-200 h-3/12">


                        </div>

                    </div>

                <div>
                    <Footer/>
                </div>





            </div>

        </>
    );
};

export default NewPassportStepTwo;