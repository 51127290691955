import React, {useEffect, useState} from 'react';
import Footer from "../assets/components/common/footer";
import {HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../assets/components/common/breadcrumb";
import {useNavigate} from "react-router-dom";
import Sidebar from "../assets/components/common/sidepane";

const Home = () => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [User, setUser] = useState([]);
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = localStorage.getItem('user');

                if (storedUser) {

                    const parsedUser = JSON.parse(storedUser);
                    setUser(parsedUser)

                    const accessToken = localStorage.getItem('accessToken');
                    setAccessToken(accessToken);

                } else{
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);


            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Dashboard', url: '/' },
    ];

    const getRoleLabel = (userType) => {
        switch (userType) {
            case 0:
                return 'Director';
            case 1:
                return 'Passport Manager';
            case 2:
                return 'Accountant';
            case 3:
                return 'Complaints';
            case 4:
                return 'Country Representative';
            default:
                return '';
        }
    };

    return (
        <>

            <div className="block h-[100vh] bg-neutral-200">

                <div className="h-[8vh] w-full px-4">

                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">

                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>

                            <div className="mt-3 relative text-gray-700">
                                {User.length === 0 ? (
                                        <div className="absolute right-0">
                                            Welcome, Loading...
                                        </div>
                                    ):
                                    (
                                        <div className="absolute right-0 capitalize">
                                            Welcome, <strong>{User.username} ({getRoleLabel(User.user_type)})</strong>
                                            {User.user_type === 4 && (
                                                <span> | Responsibility: <strong>{User.responsibility}</strong></span>
                                        )} | {formattedTime}

                                        </div>)}
                            </div>


                        </div>
                    </div>




                </div>

                <div className="bg-green-500 h-[85vh] w-full px-4">
                    {/* Render content based on user type */}
                    {User && User.user_type === 0 && (
                        // Render content for user type 0
                        <div>
                            Content for User Type 0
                        </div>
                    )}
                    {User && User.user_type === 1 && (
                        // Render content for user type 1
                        <div>
                            Content for User Type 1
                        </div>
                    )}
                    {User && User.user_type === 2 && (
                        // Render content for user type 2
                        <div>
                            Content for User Type 2
                        </div>
                    )}
                    {/* Add more conditions for other user types */}
                </div>

                <div>
                    <Footer/>
                </div>





            </div>

        </>
    );
};

export default Home;